import generateQueryUrl from '@helpers/generateQueryUrl';
import PremiumLogo from '@icons/PremiumLogo';
import {
  CartItemSourceContext,
  CheeseIcon,
  DiscountSplashIcon,
  DropPinIcon,
  FoodIcon,
  GrapeIcon,
  WineGlassIcon,
  getCartItemSource,
  getCartItemSourceProps,
  imagesUri,
  useI18N,
} from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Suspense, useContext } from 'react';

import { formatNumber } from '@lib/numbers';

import { ErrorBoundary } from '@components/ErrorBoundary/ErrorBoundary';

import styles from './menuLink.module.scss';

const renderIcon = (icon) => {
  if (icon.startsWith('icon-foodpairing-')) {
    return (
      <span className={styles.icon}>
        <ErrorBoundary>
          <Suspense fallback={<div />}>
            <FoodIcon
              id={parseInt(icon.replace('icon-foodpairing-', ''), 10)}
              className={styles.iconFood}
            />
          </Suspense>
        </ErrorBoundary>
      </span>
    );
  }
  switch (icon) {
    case 'offers':
      return (
        <span className={cx(styles.icon, styles.iconDiscount)}>
          <DiscountSplashIcon />
        </span>
      );
    case 'wine':
      return (
        <span className={cx(styles.icon, styles.iconWine)}>
          <WineGlassIcon />
        </span>
      );
    case 'pin':
      return (
        <span className={cx(styles.icon, styles.iconDropPin)}>
          <DropPinIcon />
        </span>
      );
    case 'grapes':
      return (
        <span className={cx(styles.icon, styles.iconGrapes)}>
          <GrapeIcon />
        </span>
      );
    case 'cheese':
      return (
        <span className={cx(styles.icon, styles.iconCheese)}>
          <CheeseIcon />
        </span>
      );
    case 'premium':
      return <span className={cx(styles.icon, styles.iconPremium)}>{<PremiumLogo />}</span>;
    default:
      return null;
  }
};

const renderExtra = (icon, extra) => {
  switch (icon) {
    case 'premium':
      return (
        <div
          className={styles.iconPremiumExtra}
          style={{ backgroundImage: `url(${imagesUri('union.svg')})` }}
        >
          <span className={styles.caption}>{extra}</span>
        </div>
      );
    default:
      return null;
  }
};

export const generateLinkUrl = (element) => {
  if (element.link) {
    return element.link;
  }
  return generateQueryUrl({ baseUrl: '/explore', params: [...element.queryParams] });
};

const MenuLink = ({
  element,
  onMouseEnter = null,
  className = '',
  isLinkEnabled = true,
  onClick,
}) => {
  const { t } = useI18N();
  const cartItemSourceType = useContext(CartItemSourceContext);
  const cartItemSource = getCartItemSource(cartItemSourceType);
  const cartItemSourceProp = getCartItemSourceProps(cartItemSource);

  const handleClick = () => {
    onClick?.();
  };

  if (!element?.title && !element?.noTitle) {
    return null;
  }

  if (element?.noTitle) {
    return <div className={cx(styles.menuLink, className)}></div>;
  }

  const formatTitleParam = (value, currencyCode) => {
    return formatNumber({
      amount: Number(
        String(value)
          .replace(/[^\d,]/g, '')
          .replace(',', '.')
      ),
      currencyCode,
    });
  };

  const amount = element.titleParams?.amount;
  const from = element.titleParams?.from;
  const to = element.titleParams?.to;

  if (element.titleParams) {
    if (amount) {
      element.titleParams['amount'] = formatTitleParam(amount, element.titleParams.currency_code);
    }

    if (from) {
      element.titleParams['from'] = formatTitleParam(from, element.titleParams.currency_code);
    }

    if (to) {
      element.titleParams['to'] = formatTitleParam(to, element.titleParams.currency_code);
    }
  }
  // TO DO: remove this part of code when we're ready to remove smoke test
  if (element?.icon === 'winery') {
    return null;
  }

  return (
    <>
      {isLinkEnabled ? (
        <a
          onMouseEnter={onMouseEnter}
          className={cx(styles.menuLink, className)}
          href={generateLinkUrl(element)}
          onClick={handleClick}
          {...cartItemSourceProp}
        >
          {element.icon && renderIcon(element.icon)}
          <span className={styles.text} title={t(element.title, element.titleParams)}>
            {t(element.title, element.titleParams)}
          </span>
          {element.extra && renderExtra(element.icon, t(element.extra))}
        </a>
      ) : (
        <div
          onMouseEnter={onMouseEnter}
          className={cx(styles.menuLink, className)}
          onClick={handleClick}
          {...cartItemSourceProp}
        >
          {element.icon && renderIcon(element.icon)}
          <span className={styles.text} title={t(element.title, element.titleParams)}>
            {t(element.title, element.titleParams)}
          </span>
          {element.extra && renderExtra(element.icon, t(element.extra))}
        </div>
      )}
    </>
  );
};

MenuLink.propTypes = {
  element: PropTypes.object,
  className: PropTypes.string,
  isLinkEnabled: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onClick: PropTypes.func,
};

export default MenuLink;
